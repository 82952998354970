























































































import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue"; // @ is an alias to /src
import { subscribe } from "@/api/subcribe";
@Component({
  components: {
    Layout,
  },
})
export default class Subscribe extends Vue {
  data() {
    return {};
  }
  form = {
    companyName: "",
    provinces: "",
    city: "",
    cooperationType: "",
    cooperationMode: "",
    telName: "",
    telPhone: "",
    telMail: "",
    note: "",
  };
  rules = {
    cooperationMode: [
      { required: true, message: "请选择合作模式", trigger: "change" },
    ],
    cooperationType: [
      { required: true, message: "请选择合作类型", trigger: "change" },
    ],
    telName: [
      { required: true, message: "请输入联系人姓名", trigger: "change" },
    ],
    telPhone: [
      {
        required: true,
        message: "请输入联系电话",
        trigger: "change",
      },
      {
        required: true,
        type: "number",
        message: "请输入数字",
        trigger: "change",
      },
    ],
  };

  async submit(form: any) {
    try {
      // const request = createAxiosByinterceptors()
      // const { cname, phone } = this.form;
      (this.$refs[form] as HTMLFormElement).validate((valid: boolean) => {
        if (valid) {
          // const res = decryptByDES(enCname, enkey);
          // console.log("ADMIN", ADMIN);
          const params = { ...this.form };
          console.log("params", params);

          subscribe(this.form)
            .then((res) => {
              console.log("success", res);
            })
            .catch((err) => {
              console.log("err", err);
            });
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }
}
