import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Message } from "element-ui";
// import { jumpLogin } from "@/utils";
import { Loading } from "element-ui";
import { ElLoadingComponent } from "element-ui/types/loading";
import { encryptByDES } from "@/utils/des";
const enkey = "abcdefjhijklmnopqrstuvwxyz1234567890";
const ADMIN = encryptByDES("admin", enkey);
// import vm from "@/main";
const BASEURL = 'http://47.104.214.243:9092'

let loadingInstance: ElLoadingComponent | null = null;
let requestNum = 0;

const addLoading = () => {
    // 增加loading 如果pending请求数量等于1，弹出loading, 防止重复弹出
    requestNum++;
    if (requestNum == 1) {
        loadingInstance = Loading.service({
            text: "正在努力加载中....",
            background: "rgba(0, 0, 0, 0)",
        });
    }
};

const cancelLoading = () => {
    // 取消loading 如果pending请求数量等于0，关闭loading
    requestNum--;
    if (requestNum === 0) loadingInstance?.close();
};

export const request = (
    config?: AxiosRequestConfig
): AxiosInstance => {
    const instance = axios.create({
        timeout: 1000,    //超时配置
        withCredentials: true,  //跨域携带cookie
        baseURL: BASEURL,
        ...config,   // 自定义配置覆盖基本配置
    });

    // 添加请求拦截器
    instance.interceptors.request.use(
        function (config: any) {
            // 在发送请求之前做些什么
            // const { loading = true } = config;
            console.log("config:", config);
            config.headers.Authorization = 'Bearer ' + ADMIN;
            // if (loading) addLoading();
            addLoading();
            return config;
        },
        function (error) {
            // 对请求错误做些什么
            return Promise.reject(error);
        }
    );

    // 添加响应拦截器
    instance.interceptors.response.use(
        function (response) {
            // 对响应数据做点什么
            console.log("response:", response);
            //   const { loading = true } = response.config;
            cancelLoading();
            const { code, data, msg: message } = response.data;
            if (code === 0) {
                Message.success(message)
                return data
            }
            else if (code === 401) {
                // jumpLogin();
            } else {
                Message.error(message);
                return Promise.reject(response.data);
            }
        },
        function (error) {
            // 对响应错误做点什么
            console.log("error-response:", error.response);
            console.log("error-config:", error.config);
            console.log("error-request:", error.request);
            const { loading = true } = error.config;
            if (loading) cancelLoading();
            if (error.response) {
                if (error.response.status === 401) {
                    //   jumpLogin();
                }
            }
            Message.error(error?.response?.data?.message || "服务端异常");
            return Promise.reject(error);
        }
    );
    return instance;
};

/**
 * 下载文件
 */
// import { AxiosResponse } from "axios";

// export const downloadFile = (response: AxiosResponse) => {
//     console.log("response.data.type:", response.data.type);
//     return new Promise((resolve, reject) => {
//       const fileReader = new FileReader();
//       fileReader.onload = function () {
//         try {
//           console.log("result:", this.result);
//           const jsonData = JSON.parse((this as any).result); // 成功 说明是普通对象数据
//           if (jsonData?.code !== 200) {
//             Message.error(jsonData?.message ?? "请求失败");
//             reject(jsonData);
//           }
//         } catch (err) {
//           // 解析成对象失败，说明是正常的文件流
//           const blob = new Blob([response.data]);
//           // 本地保存文件
//           const url = window.URL.createObjectURL(blob);
//           const link = document.createElement("a");
//           link.href = url;
//           const filename = response?.headers?.["content-disposition"]
//             ?.split("filename*=")?.[1]
//             ?.substr(7);
//           link.setAttribute("download", decodeURI(filename));
//           document.body.appendChild(link);
//           link.click();
//           resolve(response.data);
//         }
//       };
//       fileReader.readAsText(response.data);
//     });
//   };
