import CryptoJS from 'crypto-js';

// DES加密
export const encryptByDES = (message: string, key: string, isHex = true) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    if (isHex) return encrypted.ciphertext.toString()
    return encrypted.toString();
}

// DES解密
// export const decryptByDES = (ciphertext, key, isHex = true) => {
//     var keyHex = CryptoJS.enc.Utf8.parse(key);
//     // direct decrypt ciphertext
//     if (isHex) ciphertext = CryptoJS.enc.Hex.parse(ciphertext)
//     else ciphertext = CryptoJS.enc.Base64.parse(ciphertext)
//     var decrypted = CryptoJS.DES.decrypt({
//         ciphertext
//     }, keyHex, {
//         mode: CryptoJS.mode.ECB,
//         padding: CryptoJS.pad.Pkcs7
//     });
//     return decrypted.toString(CryptoJS.enc.Utf8);
// }
